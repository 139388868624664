<template>
<div class="cert" v-loading="pageLoading">
  <el-dialog
  custom-class="orderDialog"
    title="确认订单"
    :visible.sync="orderShow"
    width="409px"
    @close="orderShow=false">
    <div class="orderShow">
        <van-image class="cover" width="100%" fit="cover" 
        :error-icon="$placeImg"
        :loading-icon="$placeImg"
        :src="lesson && lesson.goods_arr[0].cover">
        </van-image>
        <div class="detail">
          <p class="title">{{lesson && lesson.goods_arr[0].name}}</p>
          <p class="number">有限期：1年</p>
          <p class="pay">￥{{lesson && lesson.goods_arr[0].price}}</p>
        </div>
    </div>
    <div class="orderBtn">
      <p>合计：<span>￥{{lesson.goods_arr && lesson.goods_arr[0].price}}</span></p>
      <p @click="toBuy">确认支付</p>
    </div>
  </el-dialog>
  <el-dialog
    title="扫码支付"
    :visible.sync="dialogVisible"
    custom-class="payDialog"
    width="279px"
    @close="closeBuy">
    <div class="toPay">
        <!-- <span>{{buyTime}}s后关闭</span> -->
        <span>暂时只支持「微信」扫码</span>
        <vue-qr :text="payUrl" width="200" height="200" :margin="0"></vue-qr>
        <!-- <p @click="closeBuy">已完成付款</p> -->
    </div>
    
  </el-dialog>
  <div class="content">
    <div class="left">
      <div class="top">
        <div class="tip" v-if="!ifVIPDetail">
          <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
          <img :src="$free" v-if="lesson && lesson.goods_arr[0].is_free==2"/>
          <img :src="$vip" v-else-if="lesson && lesson.goods_arr[0].is_vip_flat==1"/>
          <img :src="$noVip" v-else>
        </div>
        <van-image class="cover" width="100%" fit="cover" 
        :error-icon="$placeImg"
        :loading-icon="$placeImg"
        :src="lesson.goods_arr[0].cover">
        
        </van-image>
        <div class="detail">
          <p class="title">{{lesson && lesson.goods_arr[0].name}}</p>
          <p class="number" v-if="!ifVIPDetail">{{lesson && lesson.list.length}}课节</p>
          <div>
            <!-- VIP会员商品无论是否购买过均可继续购买 -->
            <p class="pay" v-if="lesson && !lesson.is_buy || ifVIPDetail" @click="toPay">购买：{{lesson && lesson.goods_arr[0].price}}元</p>
            <!-- 非VIP会员商品 是VIP课程且用户未购买VIP的情况下显示 -->
            <p class="pay"  style="display:none" v-if=" !ifVIPDetail && lesson && lesson.goods_arr[0].is_vip_flat && !plat_vip.is_buy" @click="toPage('./detail?id='+plat_vip.goods_id)">加入{{partalName}}会员VIP免费看</p>
          </div>
          
        </div>
      </div>
      <div class="type"  v-if="!ifVIPDetail">
        <div class="idea-nav-item" 
          @click="getTag(item.id)" 
          v-for="item in typeList" 
          :key="item.id" 
          :class="{ active: item.id == activeTab }">{{item.name}}</div>
      </div>
      <div class="detailContent">
        <!-- 课程介绍 -->
        <div class="html" v-html="lesson && lesson.goods_arr[0].content_app"  v-show="activeTab==1"></div>
      
        <!-- 视频列表 -->
        <div class="lesson-list" v-show="activeTab==2">
          <div class="lesson-item" @click="play(item)" v-for="(item,index) of lesson.list" :key="index">
            <div class="free" v-if="item.is_buy != 1 && item.is_show == 1">
              试看
            </div>
            <div class="lesson-video">
              <img :src="item.cover_mobile || $placeImg" alt="">
            </div>
            <div class="lesson-audio">
              <div class="lesson-title">{{item.lesson_name}}</div>
        
                <!-- {{lesson && item.resource[0].duration}} -->
                <div class="lesson-msg">
                  <div class="lesson-time">
                    <img
                      src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/gaoyanyuan/d6.png"
                      alt=""
                    /><p>1学时</p>
                  </div>
                  <div class="lesson-time" v-if="item.resource.length">
                    <img
                      src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/gaoyanyuan/d5.png"
                      alt=""
                    />{{ item.resource[0].id || !item.resource[0].ali_media_id ? item.resource[0].ali_duration : item.resource[0].duration}}
                  </div>
                  <div class="lesson-count" v-if="!item.is_buy && !item.is_show">
                    <img
                      src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/gaoyanyuan/d3.png"
                      alt=""
                    />无权限
                  </div>
                  <div class="lesson-count" :style="{color: color[2]}" v-else-if="item.status==0">
                    <img
                      src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/gaoyanyuan/d2.png"
                      alt=""
                    />未开始
                  </div>
                  <div class="lesson-status"  :style="{color: color[1]}" v-if="item.status==2">
                    <img
                      src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/gaoyanyuan/d1.png"
                      alt=""
                    />学习中
                  </div>
                  <div class="lesson-status"  :style="{color: color[0]}" v-if="item.status==1">
                    <img
                      src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/gaoyanyuan/d4.png"
                      alt=""
                    />已学完
                  </div>
                </div>
            </div>
            <div class="play-btn"></div>
          </div>
        </div>
        <!-- 考试 -->
        <div class="tab-content"  v-show="activeTab==3" v-if="lesson && lesson.base.is_test">
            <div  class="examin-content">
          <div class="examinLeft">
              <p class="examinTitle">{{lesson && lesson.goods_arr[0].name}}考试试题</p> 
              <p class="examinDesc">满足课程的{{lesson && lesson.base.study_time}}学时即可参加考试</p>
              <p class="score"><span>满分{{lesson.base.score}}分</span>试题数{{lesson.base.question_number}}</p>
          </div>
          <div class="examinBtn" @click="toExamin" v-if="!lesson.base.pass" :class="{opacity:!lesson.base.study_pass}">开始考试</div>
        </div>
        </div>

        <!-- 证书 -->
        <div class="tab-content"  v-show="activeTab==4" v-if="lesson && lesson.base.is_test">
            <cert-save :lessonNm = "lesson.goods_arr[0].name" :userName="userName"></cert-save>
        </div>
      </div>
      
    </div>
     
    <div class="right">
      <div class="classify">
        <div class="hot-title">喜欢这门课的人也喜欢</div>
        <div class="hot-item" v-for="item of list" :key="item.id" @click="jumpDetail(item)">
          <div class="tip">
            <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
            <img :src="$free" v-if="item.is_free==2"/>
            <img :src="$vip" v-else-if="item.is_vip_flat==1"/>
            <img :src="$noVip" v-else>
          </div>
          <van-image class="cover"
          :error-icon="$placeImg"
          :loading-icon="$placeImg"
          fit="cover" 
          :src="item.master_cover" />
          <div class="detail">
            <p class="title">{{item.name}}</p>
            <!-- <p class="subtitle">{{item.description}}</p> -->
          </div>
        </div>
        <van-empty style="margin: 0 auto;" v-if="!list.length" class="emptySmall" description="暂无数据" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import VueQr from 'vue-qr'
import certSave from './CertSave.vue';
export default {
  components: { certSave,VueQr },
  name: "detail",
  data() {
    return {
      ifVIPDetail:true,
      typeList:[{
        name:'课程介绍',
        id:1,
      },{
        name:'视频课程',
        id:2,
      },{
        name:'课程考试',
        id:3,
        is_test:true,
      }
      // ,{
      //   name:'课程证书',
      //   id:4,
      //   is_test:true
      // }
    ],
      payUrl:'',
      orderShow:false,
      dialogVisible:false,
      pageLoading: true,
      buyTimer:null,
      buyTime:300,
      menu: 2,
      id: 0,
      lesson: '',
      list: [],
      activeTab: -1,
      textContent: '',
      color: ['#666', '#FF4D41', '#6DD400'],
      payTimer:null,
      isPay:false,
      
    }
  },
  mounted() {
    if (!this.isLogin) {
        this.$store.commit('changeLoginShow', true)
        return
      };
    console.log(this.$route.query.type=='video')
    this.activeTab = this.$route.query.type=='video' ? 2 : 1
    this.$router.onReady(() => {
      console.log(this.$route)
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.getData();
      }
    })
  },
  methods: {
    getTag(id){
      this.activeTab = id
    },
    async toBuy(){
      this.orderShow = false
       
       var payPrice = Number(this.lesson.goods_arr[0].price);
      
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        // let { is_admin, mobile } = JSON.parse(this.$store.state.user);
        // let { openid } = this.$store.state.wx;
        // if (mobile == "17600789950" || mobile == "17600789951") {
        //   payPrice = 0.01;
        // }
        let res = await this.$api({
          url: "/api/order/make",
          method: "POST",
          data: {
        
            amount: payPrice,
            goods_sku_id: this.lesson.goods_arr[0].goods_sku_id,

            // amount:0.01,
            // goods_sku_id:2507,

            pay_type:12,
            amount_coin:0,
            amount_diamond:0,
            amount_award:0
   
          },
        });
        if(res.code==0){

            this.$toast.clear()
            // 限免商品
            if(this.lesson.goods_arr[0].is_free==2){
              this.$toast('购买成功')
              if(this.ifVIPDetail){
                this.$store.dispatch('getVIPInfo')
              }
            }else{
              this.dialogVisible = true
              this.payUrl = JSON.parse(res.data.pay).code_url
              this.isPay = true
              this.payTimer = setTimeout(()=>{
                this.getData()
              },1000)
            }
            

        }else{
            this.$toast(res.msg)
        }
      //  this.buyTime = 300
      //  this.buyTimer = setInterval(()=>{
      //    this.buyTime--
      //    if(this.buyTime==0){
      //     clearInterval(this.buyTimer)
      //     this.dialogVisible = false
      //    }
      //  },1000)
    },
    toExamin(){
      if(this.lesson.base.study_pass){
        this.$router.push({
          path:'./examin',
          query:{
            id:this.lesson.base.test_id
          }
        })
      }else{
        this.$toast('您还未满足考试要求')
      }
      
    },
    closeBuy(){
      
    },
    async getData() {
      
      if(!this.isPay)this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/platform/goods/detail",
        data: {
          goods_id: this.id,
          plat: this.plat,
          
        }
      });
      this.pageLoading = false;
      if (res.code == 0) {
        this.lesson = res.data
        this.list = res.data.top_list
        this.plat_vip = res.data.plat_vip
        this.typeList = this.typeList.filter(item=>{
          return !item.is_test || item.is_test && this.lesson.base.is_test
        })
        this.ifVIPDetail = res.data.goods_arr[0].category_id == 102
        // 1 为课程  2为培训
        if(res.data.base.type_id==1){
          this.$store.commit('SET_HEADER','lesson')
        }else if(res.data.base.type_id==2){
          this.$store.commit('SET_HEADER','train')
        }
        this.$store.commit('SET_VIP',this.plat_vip)
        // this.activeTab = this.ifVIPDetail ? 0 : 1
        // console.log(this.typeList)
        if(this.isPay){
          if(!res.data.is_buy){
            this.payTimer = setTimeout(()=>{
              this.getData()
            },1000)
          }else{
            this.isPay = false
            this.dialogVisible = false
          }
        }
      } else {
        this.$toast(res.msg);
      }
    },
    toPay(){
      if(localStorage.getItem('user')){
        this.orderShow=true
      }else{
        this.$toast('请登录后购买')
      }
    },
    play(item) {
      if (!item.resource.length) {}
      let {
        ali_media_id,
        ccid,
        url,
        id
      } = item.resource[0];
      let name = item.lesson_name
      console.log(ali_media_id, ccid);
      let videoId = ali_media_id
      if(item.is_buy != 1 && item.is_show != 1){ //既没有购买，也不是试看视频
          this.$toast('请购买后开始学习')
          return
      }
      this.$store.commit('SET_VIDEO',item)
      console.log(url)
      let type = 'ali';
      if (ccid && ccid!='undefined') {
        type = 'cc';
        videoId = ccid;
      }else if(url) {
        type = 'url';
      }else if(!ali_media_id){
        console.log('none')
        this.$toast('当前视频未上传')
        return
      }
      this.$router.push(`./player?goods_id=${this.id}&id=${id}&type=${type}&name=${name}&videoId=${videoId}&from=课程&url=${encodeURI(url)}`)
    },

    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`)
    },
    toPage(url){
      this.$router.push(url)
    }
  },
  computed: {
    partalName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id
    },
    userName(){
      return JSON.parse(localStorage.getItem('user')).nickname
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  watch:{
    isLogin(){
      if(this.isLogin){
       
        if (this.$route.query.id) {
          this.id = this.$route.query.id;
          this.activeTab = this.$route.query.type=='video' ? 2 : 1
          this.getData();
        }
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    console.log(to)
    this.id = to.query.id
    this.activeTab = this.$route.query.type=='video' ? 2 : 1
    this.getData();
    next()
  },
  beforeDestroy(){
    clearInterval(this.payTimer)
  }

};
</script>

<style lang="scss" scoped>
:deep .el-dialog{
  margin-top:0 !important;
  position:fixed;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%)
}
:deep .orderDialog{
  overflow: hidden;
  border-radius: 20px;
  .el-dialog__header{
    padding: 0 20px;
    height: 54px;
    display: flex;
    align-items: center;
    .el-dialog__title{
      font-size: 22px;
      color: #333333;
    }
  }
  .el-dialog__body{
    padding: 20px 0 0 0;
    border-top:1px solid #ECECEC;
  }
}
.examin-content{
  display: flex;
  align-items: center;
  width: 100%;
  // background: #f7f7f7;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 30px 20px;
  margin: 30px auto;
}
.examin-content .examinBtn{
  text-align: center;
  padding: 10px 20px;
  background: rgb(164, 0, 1);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.opacity{
  opacity: 0.5;
}
.examin-content .examinLeft{
  flex:1
}
.examin-content .examinLeft .examinTitle{
  color: #000;
  width: 100%;
  font-size: 18px;
  margin-bottom: 16px;
}
.examin-content .examinLeft .examinDesc{
  margin-bottom: 40px;
}
.examin-content .examinLeft .score span{
   margin-right:50px
}
.orderShow{
  display: flex;
  padding: 0 20px;
  margin-bottom: 40px;
  .detail{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .cover{
     width: 150px !important;
     height: 100px;
     margin-right: 20px;
     border-radius: 10px;
     overflow: hidden;
     flex-shrink: 0;
  }
  .title{
    font-size: 20px;
    color: #333;
    font-weight: bold;
     overflow: hidden;
    display: -webkit-box;
    /*弹性伸缩盒子*/
    -webkit-box-orient: vertical;
    /*子元素垂直排列*/
    -webkit-line-clamp: 2;
    /*可以显示的行数，超出部分用...表示*/
    text-overflow: ellipsis;
    /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
  }
  .number{
    font-size: 14px;
  }
  .pay{
    font-size: 20px;
    color: #FF4D41;
  }
}
.orderBtn{
  width: 100%;
  height: 54px;
  display:flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  border-top:1px solid #ECECEC;
  p{
    font-size: 18px;

    span{
      font-size: 20px;
      color: #FF4D41;
    }
  }
  p:nth-child(2){
    width: 116px;
    height: 36px;
    background: #A40001;
    border-radius: 6px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;
    cursor: pointer;
  }
}
:deep .payDialog{
  overflow: hidden;
  border-radius: 20px;
  text-align: center;
  .el-dialog__body{
    padding:0;
  }
}
.toPay{
  padding-bottom: 30px;
  span{
    color: #666;
    display: block;
    padding-bottom: 20px;
  }
  img{
    width: 200px;
  }
  >p{
    cursor: pointer;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px 0;
    width: 200px;
    text-align: center;
    margin: 20px auto 0;
  }
}
.cert {
  min-height: calc(100vh - 230px);
  overflow: hidden;

  .article {
    width: 960px;
    background: #fff;
    min-height: 100vh;
    margin: 20px auto;
    padding: 30px;

    .art-title {
      font-size: 32px;
      margin-bottom: 20px;
    }

    .art-user {
      display: flex;
      align-items: center;
    }

    .user-msg {
      font-size: 16px;
      color: #333;
      margin-left: 10px;

      .sub {
        font-size: 12px;
        color: #666;
      }
    }

    .art-content {
      font-size: 17px;
      word-break: break-word;
      line-height: 1.6;
      margin-top: 16px;
      color: #121212;
      font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    }
  }
}

.content {
  display: flex;

  .left {
    flex: 0 0 860px;
    border-radius: 12px;
    margin-top: 30px;

    .top {
      display: flex;
      background: #fff;
      padding: 20px 20px;
      position: relative;
      .tip{
        position: absolute;
        top:20px;
        left:20px;
        z-index: 1;
        img{
          width: 50px;
          // height: 35px;
        }
      }
      .cover {
        width: 300px;
        flex: 0 0 300px;
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
      }

      .detail {
        flex: 0 1 auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        div{
          display: flex;
        }
        .title {
          color: #333;
          font-weight: 500;
          font-size: 26px;
          line-height: 36px;
        }

        .number {
          color: #666;
          font-size: 16px;
          margin-top: 20px;
        }
        .pay{
          background: rgb(164, 0, 1);
          color: #fff;
          padding: 0 20px;
          margin-top: 20px;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          cursor: pointer;
          margin-right: 20px;
        }
      }
    }
    .type {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top:30px;
      margin-bottom: 20px;
    }
    .detailContent{
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
    }
    

    .lesson-list {
      padding: 0 20px;
      .lesson-item {
        position: relative;
        padding: 20px 0;
        border-bottom: 1px solid #F4F4F4;
        overflow: hidden;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:last-of-type {
          border: none;
        }
.free {
  width: 40px;
  height: 28px;
  background: #ff5d5e;
  border-radius: 5px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  position: absolute;
  right: 0px;
  top: 0px;
}
        .play-btn {
          width: 27px;
          height: 27px;
          background: url('https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BC%96%E7%BB%84%202%E5%A4%87%E4%BB%BD%202%402x.png') no-repeat center / contain;
        }

        .lesson-audio {
          flex: 1;
          margin: auto 0;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          height: 60px;
        }

        .lesson-audio .lesson-title {
          line-height: 22px;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          position: relative;
          font-size: 16px;
          display: -webkit-box;
          overflow: hidden;
          margin-bottom: 5px;
        }

       
        .lesson-msg {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 26px;
          color: #666;
          width: 100%;
        }

        .lesson-msg .lesson-time,
        .lesson-msg .lesson-count,
        .lesson-msg .lesson-status {
          display: flex;
          align-items: center;
          color: #666;
          margin-right: 20px;
          white-space: nowrap;
        }

        .lesson-msg img {
          height: 14px;
          margin-right: 5px;
        }

        .lesson-video {
          position: relative;
          float: left;
          margin-right: 10px;
        }

        .lesson-video img {
          display: block;
          width: 108px;
          height: 60px;
          border-radius: 5px;
        }

        .lesson-video .video-duration {
          position: absolute;
          bottom: 0;
          right: 0;
          font-size: 10px;
          font-weight: 500;
          color: #fff;
          height: 32px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          padding: 0 3px 3px 0;
        }

        .lesson-video::after {
          display: inline-block;
          position: absolute;
          top: 15px;
          left: 40px;
          content: '';
          width: 30px;
          height: 30px;
          background: url('https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/MTU4NDI2MDIzODA1.png') no-repeat center / contain;
        }
      }
    }

    .html {
      text-align: center;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .right {
    margin-left: 40px;
    width: 300px;
    overflow: hidden;
    margin-top: 30px;
  }

  .classify {
    width: 300px;
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;

    .hot-title {
      font-size: 22px;
      font-weight: 500;
      border-bottom: 1px solid #eee;
      padding: 0 20px 15px;
      width: 100%;
      display: block;
    }

    .hot-item {
      width: 100%;
      display: flex;
      display: flex;
      align-items: center;
      border-radius: 10px;
      padding: 10px;
      cursor: pointer;
      position: relative;
      .tip{
        position: absolute;
        top:10px;
        left:10px;
        z-index: 1;
        img{
          width: 40px;
        }
      }
      &:hover {
        background: #f8f8f8;
      }

      .cover {
        flex: 0 0 84px;
        height: 84px;
        margin-right: 10px;
       
        overflow: hidden;
        ::v-deep img{
          border-radius: 6px;
        }
      }

      .detail {
        flex: auto;

        .title {
          font-size: 16px;
          font-weight: 500;
        }

        .subtitle {
          font-size: 12px;
          color: #666;
        }
        
      }
    }
  }
}
</style>

<style lang="scss">

</style>
